<template>
<div class="gbook-entry">
    <b-row class="align-items-center">
        <b-col class="entry-date">
            {{date}}
        </b-col>
        <b-col class="entry-read text-right font-italic">
            <b-button variant="link" :id="'popover_gbook_read_'+entry.id">Läst av {{entry.readcount}}</b-button>
            <b-popover @shown="Shown()" :target="'popover_gbook_read_'+entry.id" triggers="hover" placement="bottom">
                <div v-if="read">
                    <div class="text-center mb-2">Följande användare har läst inlägget</div>
                    <b-list-group>
                        <b-list-group-item v-for="(r, index) in read" :key="index">
                            <div class="d-flex align-items-center">
                                <div class="p-1">
                                    <b-img class="gbook-avatar-small" center rounded="circle" :src="useravatar(r.userid)"></b-img>
                                    <div class="text-center mt-2">
                                        <router-link :to="userlink(r)">{{r.username}}</router-link>
                                    </div>
                                </div>
                                <div class="text-right p-1">{{readDate(r.readdate)}}</div>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </div>
                <b-spinner v-else variant="primary" label="hämntar..."></b-spinner>
            </b-popover>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col cols="4" md="3" lg="2" class="entry-image">
            <b-img fluid class="gbook-avatar" center rounded="circle" :src="avatar"></b-img>

        </b-col>
        <b-col cols="8" md="9" lg="10">
            <div class="entry-title">
                {{entry.title}}
            </div>
            <div class="entry-text" v-inview="entryInView">
                <span v-html="entry.message"></span>
            </div>
        </b-col>
    </b-row>
    <b-row class="mt-2 align-items-center">
        <b-col cols="12">
            <span class="font-italic font-small">Skrivet av </span>
            <span :id="'gbook_entry_row_' + entry.userid" class="link text-primary pointer">{{entry.username}}</span>
            <UserPopover :target="'gbook_entry_row_' + entry.userid" :user="{ userid: entry.userid, username: entry.username}"></UserPopover>            
        </b-col>
    </b-row>
</div>
</template>

<script>
export default {
    name: "GbookEntry",
    props: ["entry"],
    components: {},
    data() {
        return {
            readload: true,
            read: false,
            hasread: false,
        };
    },
    computed: {
        date() {
            return this.$date.stringToReadableFormatWithTime(this.entry.date);
        },
        avatar() {
            return this.useravatar(this.entry.userid);
        },
    },
    methods: {
        useravatar(id) {
            return this.$images.userAvatar(id);
        },
        userlink(item) {
            var user = {
                username: item.username,
                id: item.userid
            };
            return this.$url.userurl(user);
        },
        readDate(date) {
            return this.$date.stringToReadableFormatWithTimeShort(date);
        },
        Shown() {
            if (!this.read) {
                this.readload = false;
                this.$store.dispatch("gbookg/getReadList", this.entry.id).then((response) => {
                    this.read = response;
                });
            }
        },
        entryInView() {
            if (!this.hasread) {
                this.$store.dispatch("gbookp/readEntry", this.entry.id).then(() =>
                {
                    this.$store.dispatch("gbookg/getUserReadCount");
                });
                this.hasread = true;
            }
        }
    }
};
</script>
